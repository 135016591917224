:root {
  --font-main: "Noto Sans Armenian", "IQOS Sans", "Inter", "Noto Sans", sans-serif;
  --color-darkest: #000000;
  --color-darkest-rgb: 0, 0, 0;
  --color-iq-tuna: #33303c;
  --color-mine-shaft: #313131;
  --color-dove-gray: #6f6f6f;
  --color-iq-silver: #cdcdcd;
  --color-bon-jour: #e1dfe1;
  --color-wild-sand: #f5f5f5;
  --color-iq-pampas: #f6f4f0;
  --color-soft-white: #fffdfb;
  --color-white: #ffffff;
  --color-iq-blue: #7b61ff;
  --color-turquoise: #00d1d2;
  --color-pomegranate: #f32a2a;
  --color-taste-yellow: #eac702;
  --color-taste-amber: #ca5e21;
  --color-taste-silver: #c9c9c9;
  --color-taste-turquoise: #03b5b6;
  --color-taste-purple: #baa5ce;
  --color-taste-noor: #c37625;
  --color-taste-apricity: #ff9052;
  --color-taste-yugen: #b95ec2;
  --color-light-blue: #2aabee;
  --color-iq-black: #19181c;
}

* {
  margin: 0;
  padding: 0;
}

@each $size in 5, 6, 12, 16, 20, 24, 32, 36, 40, 48, 60, 65, 72, 80, 100 {
  @each $d, $direction in "t" "top", "b" "bottom", "l" "left", "r" "right" {
    .m#{$d}-#{$size} {
      margin-#{$direction}: #{$size}px;
    }

    .p#{$d}-#{$size} {
      padding-#{$direction}: #{$size}px;
    }
  }
}

$max-widths: 320, 360;

@each $max-width in $max-widths {
  .Mw-#{$max-width} {
    max-width: #{$max-width}px;
  }
}

.mx-auto {
  margin-inline: auto;
}