.preloader {
  top: 0;
  left: 0;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 8000;
  background: var(--color-soft-white);
}

.hide {
  z-index: -9999;
  opacity: 0;
  animation: hide 250ms;
}

@keyframes hide {
  0% {
    opacity: 1;
    z-index: 8000;
  }
  80% {
    opacity: 1;
    z-index: 8000;
  }
  100% {
    opacity: 0;
    z-index: -9999;
  }
}
